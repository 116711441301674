import React, { FunctionComponent, ReactElement } from "react"

interface Props {
  icon: ReactElement
  text: string
}

const FeatureHeading: FunctionComponent<Props> = ({ icon, text }) => (
  <h3 className="mb-6 text-4 md:text-6">
    {icon}
    {text}
  </h3>
)

export default FeatureHeading
