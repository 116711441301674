import React, { FunctionComponent, ReactElement } from "react"

interface Props {
  heading: ReactElement
  image: ReactElement
  layout: string
  summary: ReactElement | string
}

const Feature: FunctionComponent<Props> = ({
  heading,
  image,
  layout,
  summary,
}) => {
  const inverted = layout === "inverted"

  return (
    <article
      className={`mt-32 md:flex ${
        inverted ? "md:flex-row-reverse" : "md:flex-row"
      } md:flex-no-wrap md:items-center md:justify-between`}
    >
      <section className="mb-10 px-10 md:mb-10 md:w-1/2 lg:1/3">
        {heading}
        {summary}
      </section>

      <aside className="px-10 md:w-1/2 lg:2/3">{image}</aside>
    </article>
  )
}

export default Feature
