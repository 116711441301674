import React, { FunctionComponent } from "react"

interface Props {
  alt: string
  src: string
}

const FeatureIcon: FunctionComponent<Props> = ({ alt, src }) => (
  <img alt={alt} src={src} className="block h-12 mb-6 w-12 md:h-16 md:w-16" />
)

export default FeatureIcon
