import React, { FunctionComponent } from "react"
// @ts-ignore
import imageStyles from "./FeatureImage.module.css"
import Img from 'gatsby-image'

interface Props {
  alt: string
  src: any
  variant: string
}

const FeatureImage: FunctionComponent<Props> = ({ alt, src, variant }) => {
  const variantStyles = imageStyles[variant ?? "alpha"]

  return (
    <div className={`feature-image ${imageStyles.image} ${variantStyles}`}>
      <Img alt={alt} fluid={{
        ...src,
      sizes: "(max-width: 300px) 200px, (max-width: 400px) 300px, (max-width: 600px) 500px, (max-width: 800px) 600px, (max-width: 1023px) 832px, (max-width: 1200px) 500px, (max-width: 1400px) 600px, (max-width: 1680px) 832px, 832px"
      }} className="block relative w-full z-10" />
    </div>
  )
}

export default FeatureImage
