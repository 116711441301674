import React, { FunctionComponent } from "react"
import styles from "./BreezyLife.module.css"
import Img from 'gatsby-image'
import Callout from "./Callout"

type Variant = "after" | "before"

interface Props {
  imageAlt: string
  imageUrl: any
  notes: string[]
  variant: Variant
}

function getVariantClass(variant: Variant) {
  return variant === "after" ? styles.after : styles.before
}

const BreezyLife: FunctionComponent<Props> = ({
  imageAlt,
  imageUrl,
  notes,
  variant,
}) => (
  <div className={`${styles.root} ${getVariantClass(variant)}`}>
    <ul className={styles.list}>
      {notes.map((note, index) => (
        <li key={index}>{note}</li>
      ))}
    </ul>

    <Img alt={imageAlt} loading="eager" fluid={{
      ...imageUrl,
      sizes: "(max-width: 300px) 200px, (max-width: 400px) 300px, (max-width: 600px) 500px, (max-width: 800px) 600px, (max-width: 1023px) 832px, (max-width: 1200px) 500px, (max-width: 1400px) 600px, (max-width: 1680px) 832px, 832px"
    }} className="w-full lazyload" />
  </div>
)

export default BreezyLife
