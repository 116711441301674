import React, { FunctionComponent } from "react"
import styles from "./HowSteps.module.css"

interface Props {
  steps: string[]
}

const HowSteps: FunctionComponent<Props> = ({ steps }) => (
  <ol className={styles.root}>
    {steps.map((step, index) => (
      <li key={index}>{step}</li>
    ))}
  </ol>
)

export default HowSteps
