import React from "react"
import Pill from "../Pill"
import Callout from "./Callout"
import NotifyForm from "../NotifyForm"
import styles from "./Hero.module.css"

export default function Hero() {
  return (
    <header className={`hero ${styles.root}`}>
      <Pill bgClass="bg-green-100" variant="bottom-right">
        <div className="px-6 py-24">
          <div className="container flex flex-col flex-no-wrap items-center mx-auto text-center">
            <div className="mb-8" style={{ maxWidth: "50ch" }}>
              <h1>
                Your{" "}
                <strong className={`interjection ${styles.interjection}`}>
                  dream
                </strong>{" "}
                solution for partner discovery
              </h1>
            </div>

            <div className="mb-10" style={{ maxWidth: "65ch" }}>
              <p className="md:text-4">
                We remove the hassle from finding prospects, so you can focus on
                generating successful partnerships
              </p>
            </div>

            <NotifyForm />

            <div className="mt-2">
              <Callout>
                <span className="inline-block text-3">
                  <strong className="font-black">Speak to our<br />partner experts</strong>.
                </span>
              </Callout>
            </div>
          </div>
        </div>
      </Pill>
    </header>
  )
}
