import React, { FunctionComponent } from "react"
import { graphql, PageProps } from "gatsby"
import Container from "../components/Container"
import Feature from "../components/home/Feature"
import FeatureHeading from "../components/home/FeatureHeading"
import FeatureIcon from "../components/home/FeatureIcon"
import FeatureImage from "../components/home/FeatureImage"
import Grid from "../components/Grid"
import Highlight from "../components/home/Highlight"
import Pill from "../components/Pill"
import ActionPanel from "../components/home/ActionPanel"
import Callout from "../components/home/Callout"
import Hero from "../components/home/Hero"
import Layout from "../components/Layout"
import NotifyForm from "../components/NotifyForm"
import "./index.module.css"
import HowSteps from "../components/home/HowSteps"
import BreezyLife from "../components/home/BreezyLife"
import Seo from "../components/Seo"

import TopArticles from '../components/blog/TopArticles'

export const query = graphql`
   query {
        screenshot: allFile(filter: {absolutePath: {regex: "//images/screenshot-/"}}) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 832) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        beforeAfter: allFile(filter: {absolutePath: {regex: "//images/before|after/"}}) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 832) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
`

interface QueryResult {
  screenshot: {nodes: {childImageSharp: {fluid: any}}[] }
  beforeAfter: {nodes: {childImageSharp: {fluid: any}}[] }
}

type HomeProps = PageProps<QueryResult>

const HomePage:FunctionComponent<HomeProps> = ({data}) => (
  <Layout>
    <Seo
      description="Breezy removes the hassle from finding prospects, so you can focus on generating successful partnerships."
      pathname="/"
      title="Breezy Partnerships | Your Dream Solution for Partner Discovery"
    />
    <Hero />
    <section className="py-24 lg:py-40">
      <section className="mb-20 px-6 md:mb-24">
        <Container>
          <Grid className="items-center">
            <section className="col-span-12 sm:pb-8 xl:col-span-5 xl:-mt-4 xl:pb-0 xxxl:col-start-2">
              <h2>Life before Breezy</h2>
              <p>
                The average affiliate or partnership manager spends{" "}
                <Highlight text="35% of their time" /> sourcing new partners!
                Are you a manager grimacing at the thought?
              </p>
            </section>

            <section className="col-span-12 xl:col-span-6 xl:col-start-7">
              <BreezyLife
                imageAlt="Illustration of an overworked manager"
                imageUrl={data.beforeAfter.nodes[1].childImageSharp.fluid}
                variant="before"
                notes={[
                  "Attending conferences",
                  "Analysing competitors",
                  "Searching your affiliate network",
                  "Sifting social networks",
                  "Googling again and again",
                  "Posting content to generate requests",
                ]}
              />

              <div className="text-center xl:text-left xxl:-mt-6 xxl:pl-8">
                <Callout>
                  <span className="font-black inline-block text-3">
                    Eugh! This is taking up too much of my time
                  </span>
                </Callout>
              </div>
            </section>
          </Grid>
        </Container>
      </section>

      <section className="px-6">
        <Container>
          <Grid className="items-center">
            <section className="col-span-12 sm:pb-8 xl:col-span-5 xl:col-start-8 xl:-mt-4 xl:pb-0">
              <h2>Life after Breezy</h2>
              <p>
                Our automated discovery engine helps you find and organise
                relevant partners <Highlight text="(way) more quickly" />, all
                in one place.
              </p>
            </section>

            <section className="col-span-12 xl:col-span-6 xl:col-start-1 xl:row-start-1">
              <BreezyLife
                imageAlt="Illustration of a happy manager"
                imageUrl={data.beforeAfter.nodes[0].childImageSharp.fluid}
                variant="after"
                notes={["Partner discovery is easy breezy"]}
              />

              <div className="mt-4">
                <Callout useTilt="inverted">
                  <span className="font-black inline-block text-3">
                    Yay! You’ll have freed up time for management
                  </span>
                </Callout>
              </div>
            </section>
          </Grid>
        </Container>
      </section>
    </section>

    <Pill bgClass="bg-green-100" variant="top-left">
      <div className="pt-32">
      <section className="px-6">
        <div className="container mx-auto text-center">
          <h2>How it works</h2>
          <p>Start finding partners in a few simple steps</p>

          <HowSteps
            steps={[
              "Feed us competitors and keywords to power our Discovery Engine.",
              "We fill up your Dashboard with relevant Leads and helpful insights on them.",
              "You identify those Prospects you’re most interested in.",
              "Get verified contact info for each Prospect to start your outreach.",
            ]}
          />
        </div>
      </section>
      </div>
    </Pill>

    <Pill bgClass="bg-green-100" variant="bottom-right">
      <div className="py-32">
        <ActionPanel>
          <h2 className="mb-6 text-4 md:mb-8 md:text-6">
            Most businesses struggle to navigate the vast longtail of marketing partnership opportunities.
          </h2>

          <p className="mb-10 md:mb-16">
            Don’t let the gems go overlooked!
          </p>

          <NotifyForm />

          <div className="mt-4">
            <Callout useTheme="inverted">
              <span
                className="inline-block font-black text-2"
                style={{ maxWidth: "15ch" }}
              >
                Turbocharge your partnerships
              </span>
            </Callout>
          </div>
        </ActionPanel>
      </div>
    </Pill>

    <section>
      <div className="container mx-auto max-w-none xxxl:max-w-screen-xxxl  py-24">
        <h2 className="text-center">What makes Breezy so awesome?</h2>

        <Feature
          heading={
            <FeatureHeading
              icon={
                <FeatureIcon
                  alt="Settings icon"
                  src="/images/icon-settings.png"
                />
              }
              text="The most tailored discovery engine ever"
            />
          }
          image={
            <FeatureImage
              alt="Screenshot of tailor research panel"
              src={data.screenshot.nodes[1].childImageSharp.fluid}
              variant="alpha"
            />
          }
          layout="default"
          summary={
            <p>
              We distilled our vast experience finding relevant partners into
              the most tailored discovery solution ever built.
            </p>
          }
        />

        <Feature
          heading={
            <FeatureHeading
              icon={
                <FeatureIcon alt="Rocket icon" src="/images/icon-rocket.png" />
              }
              text="Unlimited partner opportunities"
            />
          }
          image={
            <FeatureImage
              alt="Screenshot of research progress bar with partner logos"
              src={data.screenshot.nodes[0].childImageSharp.fluid}
              variant="bravo"
            />
          }
          layout="inverted"
          summary={
            <p>
              We don’t source partners from just one network because they could
              be anywhere. We search the whole web.
            </p>
          }
        />

        <Feature
          heading={
            <FeatureHeading
              icon={
                <FeatureIcon alt="Shapes icon" src="/images/icon-shapes.png" />
              }
              text="We cater for a wide range of partners"
            />
          }
          image={
            <FeatureImage
              alt="Screenshot of various research result panels"
              src={data.screenshot.nodes[2].childImageSharp.fluid}
              variant="charlie"
            />
          }
          layout="default"
          summary={
            <p>
              From traditional affiliates to influencers to complimentary
              businesses, we uncover relevant partners of all shapes and sizes.
            </p>
          }
        />
      </div>
    </section>

    <TopArticles link="/blog"/>


    {/*<Pill bgClass="bg-green-100" variant="top-left">*/}
      <div className="py-32">
        <ActionPanel>
          <h2 className="mb-6 text-4 md:mb-8 md:text-6">
            Speak to us to level up your partner discovery.
          </h2>

          <p className="mb-10 md:mb-16">
            Join the industry leaders already <strong>finding partners</strong> and benefitting from <strong>market intelligence</strong> from our partnership platform.
          </p>

          <div className="max-w-xl mx-auto">
            <NotifyForm />
          </div>
        </ActionPanel>
      </div>
    {/*</Pill>*/}
  </Layout>
)

export default HomePage
